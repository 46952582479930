<template>
    <admin-dashboard-layout>
        <v-data-table
            :search="search"
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="reports"
            :items-per-page="quantityItems"
            class="elevation-1"
            @click:row="handleClickRow"
        >
            <template v-slot:top>
                <v-text-field
                    v-model="search"
                    label="Search by name"
                    class="mx-4"
                ></v-text-field>
            </template>
            <template v-slot:item.read_at="{ item }">
                <div
                    style="border-radius: 50%; width: 12px; height: 12px"
                    :style="
                        item.read_at ? 'background: green;' : 'background: red;'
                    "
                ></div>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ moment(item.created_at).format("LLL") }}
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";
import { ADMIN_QUANTITY_ITEMS_LIST } from "@/configs/constants";
import UserReport from "@/models/admin/UserReport";
/* eslint-disable */
export default {
    name: "user-reports-index",
    components: { AdminDashboardLayout },
    data: function () {
        return {
            loading: false,
            quantityItems: ADMIN_QUANTITY_ITEMS_LIST,
            reports: [],
            search: "",
            headers: [
                {
                    text: "# ID",
                    align: "start",
                    sortable: false,
                    value: "id",
                },
                { text: "Viewed", value: "read_at" },
                { text: "Reporter", value: "reporter.name" },
                { text: "Accusation", value: "accusation" },
                { text: "Dependant", value: "dependant.name" },
                { text: "Blocked at", value: "dependant.blocked_at" },
                { text: "Created at", value: "created_at" },
            ],
        };
    },
    methods: {
        handleClickRow(creator) {
            this.$router.push({
                name: "admin.user-reports.show",
                params: { id: creator.id },
            });
        },
    },
    async mounted() {
        this.loading = true;
        this.reports = await UserReport.include([
            "reporter",
            "dependant",
        ]).get();
        this.loading = false;
    },
};
</script>

<style scoped></style>
